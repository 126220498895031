.important-links {
  .section {
    margin-bottom: 40px;
    .section__heading {
      color: gold;
      text-align: center;
      position: relative;
      transition: all 0.3s;

      &:hover {
        letter-spacing: 3px;
      }
    }
    .section-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;

      @include tablet {
        gap: 20px;
      }
      @include portraitTablet {
        grid-template-columns: repeat(2, 1fr);
      }
      @include largeMobile {
        gap: 16px;
      }

      .section__card {
        text-align: center;
        border: 1px solid gold;
        border-radius: 15px;
        padding: 20px;
        transition: all 0.3s;

        @include largeMobile {
          padding: 8px;
        }

        &:hover {
          scale: 1.05;

          .section__card--img {
            transform: rotateY(180deg);
          }
          .section__card--title {
            color: gold;
          }
        }

        .section__card--img {
          width: 60px;
          padding: 20px 0;
          transition: all 0.3s;

          @include tablet {
            width: 40px;
            padding: 15px 0;
          }
        }
        .section__card--title {
          font-size: 24px;
          line-height: 1.2;
          color: #fff;

          @include tablet {
            font-size: 18px;
          }
        }
      }
    }
  }
}
