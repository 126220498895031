.blogs-section {
  .blog {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 20px;
    border: 1px solid #000000;
    border-radius: 5px;
    transition: all 0.3s;

    &:hover {
      border: 1px solid gold;

      .blog__content--category {
        letter-spacing: 1px;
      }
    }

    @include tablet {
      flex-direction: column;
    }

    .blog__image {
      width: 350px;

      @include smallDesktop {
        width: 250px;
      }
      @include largeMobile {
        width: 100%;
      }
      .blog__image--img {
        border-radius: 5px 0 0 5px;
        width: 350px;

        @include smallDesktop {
          width: 250px;
        }
        @include largeMobile {
          width: 100%;
        }
      }
    }
    .blog__content {
      padding: 10px;
      .blog__content--category {
        color: gold;
        font-weight: 600;
        transition: all 0.3s;
      }
      .blog__content--title {
        margin-top: 10px;
        font-size: 24px;
        font-weight: 600;
        color: #fff;
        transition: all 0.3s;
      }
      .blog__content--date {
        margin-top: 10px;
        font-size: 15px;
        color: gray;
      }
      .blog__content--description {
        margin-top: 10px;
        font-size: 15px;
        line-height: 1.3;
        color: #fff;
      }
    }
  }
}
