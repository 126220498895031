.singleBlog {
  margin-bottom: 100px;
  .singleBlog__heading {
    font-size: 32px;
    color: gold;
    margin-bottom: 16px;
  }
  .singleBlog__date {
    color: gray;
    margin-bottom: 40px;
  }
  .blog__image {
    margin-bottom: 28px;
    // text-align: center;
    .blog__image--img {
      max-width: 500px;
      width: 50%;
      max-height: 250px;

      @include miniPortraitTablet {
        width: 100%;
      }
    }
  }

  .blog--title {
    font-size: 26px;
    margin-bottom: 16px;
  }
  .blog--paragraph {
    margin-bottom: 24px;
    line-height: 1.3;
  }
}
