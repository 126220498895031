// .home-page {
.container-wrapper {
  margin-top: 40px;

  @include tablet {
    margin-top: 40px;
  }

  .blog-widget-container {
    display: flex;
    gap: 40px;

    @include tablet {
      flex-direction: column;
      gap: 0px;
    }

    .section__heading {
      font-weight: 700;
      color: gold;
      transition: all 0.3s;
      margin-top: 0;

      &:hover {
        letter-spacing: 3px;
      }

      @include tablet {
        text-align: center;
      }
    }
    .blogs {
      width: 70%;

      @include tablet {
        width: 100%;
      }
    }
    .widgets {
      width: 30%;

      @include tablet {
        width: 100%;
      }
    }
  }

  .donate-section {
    text-align: center;
    margin-bottom: 100px;

    .donate-now__btn {
      background-color: gold;
      padding: 14px 20px;
      border: 1px solid gold;
      border-radius: 5px;
      transition: all 0.3s;
      font-weight: 600;

      &:hover {
        background-color: transparent;
        color: gold;
      }

      @include tablet {
        padding: 10px 16px;
      }
    }
  }
}
// }
