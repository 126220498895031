.navbar-section {
  z-index: 10;
  font-family: "Source Sans Pro", arial, sans-serif;

  .navbar__logo {
    height: 100px;
    margin: 10px 0 20px;
    display: flex;
    justify-content: center;

    @include tablet {
      height: 80px;
    }

    .navbar__logo--img {
      height: 100%;
    }

    .navbar__logo--text {
      color: #fff;
      font-size: 32px;
      font-weight: 600;
    }
  }

  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navbar-right {
      width: 100%;

      .large-device-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include smallDesktop {
          display: none;
        }

        .large-device-menu__item {
          border: 1px solid gold;
          width: 100%;
          text-align: center;
          padding: 15px;
        }

        a {
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          transition: all 0.3s;

          &:hover {
            color: gold;
          }
        }
      }

      .login--button {
        background-color: gold;
        padding: 10px 20px;
        color: #000 !important;
        font-weight: 600;
        border: 1px solid gold;
        transition: all 0.3s;
        display: flex;
        gap: 10px;
        align-items: center;

        &:hover {
          background: transparent;
          color: gold !important;
        }
      }

      // small-device-menu
      .small-device-menu {
        display: none;

        @include smallDesktop {
          display: block;
        }

        .menu-bar {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .small-device__sidebar-toggle {
            user-select: none;
            width: 1em;
            height: 1em;
            display: inline-block;
            fill: currentcolor;
            flex-shrink: 0;
            transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            font-size: 24px;
            cursor: pointer;
            color: gold !important;
          }
        }

        .sidebar-wrapper {
          display: none;
          .sidebar {
            animation: sidebar-open 0.4s ease-in-out;
            animation-fill-mode: forwards;
            position: fixed;
            top: 0;
            left: 0;
            color: #fff;
            background-color: #000;
            height: 100vh;
            z-index: 1000;
            width: 100%;
            transition: all 1s;
            transition-duration: 1000ms;

            @keyframes sidebar-open {
              0% {
                opacity: 0;
                transform: translateX(-100%);
              }

              100% {
                opacity: 1;
                transform: translateX(0);
              }
            }

            .sidebar__header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid rgb(73, 73, 73);

              .header__closeButton {
                .button {
                  background-color: transparent;
                  border: none;
                  color: #fff;
                  padding: 24px;

                  .button-icon {
                    font-size: 20px;
                  }
                }
              }
            }

            .sidebar__body {
              padding: 0 22px;
              .body__menu {
                font-weight: 500;
                line-height: 1.6;
                letter-spacing: 0.0075em;
                font-size: 16px;
                list-style: none;
                border-bottom: 1px solid rgb(73, 73, 73);
                padding: 12px 24px;
                a {
                  text-decoration: none;
                  color: #fff;
                  font-size: 20px;
                  font-weight: 500;
                }
              }
            }
          }
          .sidebar__backdrop {
            position: absolute;
            top: 0;
            right: 0;
            width: 100vw;
            height: 100vh;
            background-color: #fff;
          }
        }

        .open {
          display: block;
        }
      }
    }
  }
}

// Dropdown Menu
.dropdown {
  position: relative;
  display: inline-block;

  &:hover {
    .dropdown-content {
      display: block;
    }
  }

  .dropdown__btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .dropdown-content {
    border-radius: 5px;
    display: none;
    position: absolute;
    right: 0;
    min-width: 220px;
    z-index: 1;
    padding-top: 20px;

    a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;

      &:hover {
        font-weight: 700;
      }
    }
  }
}
