.hero-section {
  .hero-container {
    padding-bottom: 40px;
    .hero__body {
      .hero__item {
        position: relative;
        border-radius: 10px;

        .hero__item--img {
          max-height: 80vh;
          width: 100%;
          border-radius: 10px;
          margin: 0 auto;
        }
      }
    }

    // Slider custom style
    .slick-list {
      padding-bottom: 20px !important;
    }
    .slick-dots li button:before {
      color: #fff;
      opacity: 0.9;
    }
    .slick-dots li.slick-active button:before {
      color: #fff !important;
      width: 18px;
      height: 18px;
      border: 1px solid #fff !important;
      border-radius: 100%;
    }
    .slick-prev,
    .slick-next {
      display: none !important;
    }
    .slick-prev:before,
    .slick-next:before {
      display: none;
    }
  }
}
