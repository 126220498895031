.blogs {
  .blogs-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
    }
    @include miniPortraitTablet {
      grid-template-columns: repeat(1, 1fr);
    }

    .blogs__single {
      border: 1px solid gold;
      border-radius: 10px;
      transition: all 0.3s;

      &:hover {
        scale: 1.03;
      }

      .blogs__single--img {
        border-radius: 10px;
        width: 100%;
        max-height: 225px;
      }
      .blogs__content {
        padding: 20px;

        .blogs__content--category {
          color: gold;
        }
        .blogs__content--heading {
          font-size: 24px;
          font-weight: 600;
          color: #fff;
          margin-top: 10px;
        }
      }
    }
  }
}
